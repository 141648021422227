import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import mq from '../utils/mq';
import NotFoundIcon from '../images/svg/notfound.svg';

const Content = styled.div`
  margin: 0 auto;
  max-width: 860px;
  height: 100%;
  padding: 1.45rem 1.0875rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h1`
  font-size: 2rem;
  margin: 1rem 0;
  text-align: center;
  word-wrap: break-word;

  ${mq.bpw1} {
    font-size: 2.5rem;
  }
`;

const Text = styled.p`
  margin-top: 2.5rem;
  padding: 0 2rem;
  text-align: center;
`;

const Return = styled(Link)`
  margin-top: 1.5rem;

  ${mq.bpw1} {
    margin-top: 2rem;
  }
`;

const iconCss = css`
  width: 11rem;
  height: 11rem;

  ${mq.bph1} {
    width: 13rem;
    height: 13rem;
  }

  ${mq.bpwh1} {
    width: 13.5rem;
    height: 13.5rem;
  }
`;

const NotFoundPage = () => (
  <Layout
    main={
      <Content>
        <SEO title="404: Not found" />
        <Title>Oops! This page doesn't exist!</Title>
        <NotFoundIcon css={iconCss} />
        <Text>Looks like you just hit a route that doesn&#39;t exist...</Text>
        <Return title="Home" to="/">Return to the home page</Return>
      </Content>
    }
  ></Layout>
);

export default NotFoundPage;
